<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Model</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Model No *"
                    v-model="qmsModel.modelNo"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Model No'"
                    @blur="$v.qmsModel.modelNo.$touch()"
                    :is-valid="
                      $v.qmsModel.modelNo.$dirty
                        ? !$v.qmsModel.modelNo.$error
                        : null
                    "
                  />
                  <CInput
                    label="Equipment No *"
                    v-model="qmsModel.equipmentNo"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Model No'"
                    @blur="$v.qmsModel.equipmentNo.$touch()"
                    :is-valid="
                      $v.qmsModel.equipmentNo.$dirty
                        ? !$v.qmsModel.equipmentNo.$error
                        : null
                    "
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "QMSModel",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      qmsModelId: "",
      qmsTemplateId: this.$route.params.qmsTemplateId,
      qmsModel: {
        qmsModelId: "0",
        modelNo: "",
        equipmentNo: "",
        qmsTemplateId: this.$route.params.qmsTemplateId,
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    qmsModel: {
      modelNo: {
        required,
      },
      equipmentNo: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.qmsTemplateId = this.$route.params.qmsTemplateId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.qmsModelId = this.$route.params.qmsModelId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.qmsModelId = this.$route.params.qmsModelId;
      this.qmsTemplateId = this.$route.params.qmsTemplateId;
      this.getUser();
    }
  },
  components: { datetime },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      //this.$router.push({ name: 'QMSModels' })
      window.history.back();
    },

    save() {
      this.$v.qmsModel.$touch();
      if (this.$v.qmsModel.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.qmsModel));
      } else {
        // fetch(process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/qms/modal", {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: JSON.stringify(this.qmsModel),
        // })

        this.apiPostSecure(
          process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/qms/modal",
          this.qmsModel
        ).then((qmsModel) => {
          console.log(JSON.stringify(qmsModel));
          //this.$router.push({ name: "QMSModels" });
          window.history.back();
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/qms/model/" +
          this.qmsModelId
      )
        .then((response) => response.json())
        .then((data) => (this.qmsModel = data));
    },
  },
  mounted() {
    //this. getRoles();
  },
};
</script>
